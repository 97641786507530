import React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'theme';

const StyledBox = styled.div`
  padding: 80px 180px 80px 180px;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 40px 24px;
  }
`;

interface Props {
  children: React.ReactNode;
}

const Box = ({ children }: Props) => {
  return <StyledBox>{children}</StyledBox>;
};

export default Box;
