import flagSpain from 'images/flag-spain-wavy.png';
import flagPortugal from 'images/flag-portugal-wavy.png';
import flagUk from 'images/flag-uk-wavy.png';

export const AVAILABLE_LOKALISE_LANGUAGES = [
  {
    abbreviation: 'en',
    language: 'English',
  },
  {
    abbreviation: 'es',
    language: 'Español',
  },
  {
    abbreviation: 'pt',
    language: 'Português',
  },
  {
    abbreviation: 'it',
    language: 'Italiano',
  },
];
