import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

import 'i18n/config';

const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};

export default wrapRootElement;
