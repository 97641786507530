import React from 'react';
import 'styles/global.css';
import { ThemeProvider } from 'styled-components';

import theme from 'theme';
import Footer from 'components/Footer';
import Header from 'components/Header';
import NavigationColor from 'utils/constants/NavigationColor';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header variant={NavigationColor.DARK} />
        {children}
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default Layout;
