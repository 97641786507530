import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import NavigationColor from 'utils/constants/NavigationColor';
import { breakpoints } from 'theme';

const NavItem = styled.li<{
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
}>`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(14)};
  color: ${({ variant, theme }) =>
    variant === NavigationColor.LIGHT
      ? theme.neutralPalette.white
      : theme.brandPalette.dark};

  &:hover {
    cursor: pointer;
    color: ${({ variant, theme }) =>
      variant === NavigationColor.LIGHT
        ? theme.brandPalette.primary
        : theme.brandPalette.secondary};
  }

  .active {
    color: ${(props) => props.theme.brandPalette.primary};
  }

  @media (max-width: ${breakpoints.laptop}) {
    a {
      font-size: 18px;
      line-height: 22px;
    }
  }
`;

interface NavigationItemProps {
  href: string;
  target?: string;
  active?: boolean;
  children: React.ReactNode;
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
}

const NavigationItem = ({
  href,
  target,
  children,
  variant,
  active,
}: NavigationItemProps) => {
  return (
    <NavItem variant={variant}>
      <a
        className={active ? 'active' : ''}
        href={href}
        target={target || '_self'}
      >
        {children}
      </a>
    </NavItem>
  );
};

export default NavigationItem;
