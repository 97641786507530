import i18n from 'i18next';
import { initReactI18next } from 'gatsby-plugin-react-i18next';

import translationEN from 'assets/locales/en/en.json';
import translationES from 'assets/locales/es/es.json';
import translationIT from 'assets/locales/it/it.json';
import translationPT from 'assets/locales/pt/pt.json';

export const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
  pt: {
    translation: translationPT,
  },
} as const;

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
