// colors to be decided whenever design is ready
const theme = {
  brandPalette: {
    primary: '#367FFF',
    secondary: '#1D4486',
    dark: '#293040',
    light: '#F6F8FB',
    black: '#000000',
  },
  neutralPalette: {
    white: '#FFFFFF',
    neutral1: '#F8F9FA',
    neutral2: '#F1F3F5',
    neutral3: '#E9ECEF',
    neutral4: '#DEE2E6',
    neutral5: '#CED4DA',
    neutral6: '#ADB5BD',
    neutral7: '#6A7178',
    neutral8: '#4F575E',
    neutral9: '#272B30',
    black: '#000000',
  },
  semanticPalette: {
    info: {
      base: '#017AAD',
      light: '#E6F2F7',
    },
    success: {
      base: '#29823B',
      light: '#EAF3EB',
    },
    warning: {
      base: '#F7A500',
      light: '#FEF1D9',
    },
    error: {
      base: '#D35549',
      light: '#FBEEED',
    },
  },
  fonts: {
    body: 'Rubik, sans-serif',
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};

export const breakpoints = {
  tablet: '768px',
  laptop: '1024px',
  desktop: '1280px',
};

export default theme;
