import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import dropdownArrow from 'images/dropdown-arrow-blue.png';
import dropdownAngleDown from 'images/angledown.svg';
import dropdownAngleUp from 'images/angle-up.svg';

import { breakpoints } from 'theme';
import { AVAILABLE_LOKALISE_LANGUAGES } from 'utils/constants';

import { useI18next } from 'gatsby-plugin-react-i18next';
import NavigationColor from 'utils/constants/NavigationColor';

const Container = styled.div<{ isOpen: boolean }>`
  display: block;
  background-color: transparent;
  font-size: ${rem(16)};
  line-height: ${rem(24)};

  position: relative;

  @media (max-width: ${breakpoints.laptop}) {
    right: -2%;
  }
`;

const SelectedOption = styled.button<{
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
  isOpen: boolean;
}>`
  width: 100%;
  border-radius: 8px;
  background: #f6f8fb;
  color: ${({ theme, variant }) =>
    variant === NavigationColor.DARK ? '#000' : theme.neutralPalette.white};

  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  display: flex;
  align-items: center;

  padding: 16px;
  margin-right: 30px;
  margin-left: 5px;

  background-image: url(${dropdownArrow});
  background-repeat: no-repeat;
  background-position: 75% 50%;

  @media (max-width: ${breakpoints.laptop}) {
    font-size: ${rem(18)};
    background-position: 75% 53%;
    background-color: #fff;
    background-image: url(${(props) =>
      props.isOpen ? dropdownAngleUp : dropdownAngleDown});
  }
  @media (max-width: ${breakpoints.tablet}) {
    background-position: 70% 55%;
  }
`;

const OptionsList = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  width: 150%;
  height: 365%;
  padding: 16px;
  flex-shrink: 0;
  background-color: #f6f8fb;
  box-shadow: 1px 2px 18px rgb(0 0 0 / 25%);

  transition: all 300ms ease-in-out;

  max-height: 260px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  position: absolute;
  z-index: 2;
  border-radius: 8px;

  @media (max-width: ${breakpoints.laptop}) {
    right: 15%;
    width: 130%;
    height: 355%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    right: 15%;
    width: 130%;
    height: 350%;
  }
`;

const Option = styled.li<{ fadedClr: boolean }>`
  text-align: right;
  font-size: ${rem(18)};
  line-height: ${rem(21)};
  font-weight: ${({ theme, fadedClr }) =>
    fadedClr ? theme.fontWeights.bold : theme.fontWeights.regular};
  color: ${({ theme, fadedClr }) =>
    fadedClr ? theme.brandPalette.dark : theme.neutralPalette.neutral7};
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;

interface Props {
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
}

const LanguageDropdown = ({ variant }: Props) => {
  const { changeLanguage, language } = useI18next();

  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>(language);
  const ref = useRef(null);

  const handleDropdownClick = () =>
    setDropdownIsOpen((previousState) => !previousState);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleLangClick = (lang: string) => {
    if (lang === language) return;

    changeLanguage(lang);
    setSelectedOption(lang);
    setDropdownIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Container ref={ref} isOpen={dropdownIsOpen}>
      <SelectedOption
        type="button"
        variant={variant}
        isOpen={dropdownIsOpen}
        onClick={handleDropdownClick}
      >
        {capitalizeFirstLetter(selectedOption)}
      </SelectedOption>
      {dropdownIsOpen && (
        <OptionsList isOpen={dropdownIsOpen}>
          {AVAILABLE_LOKALISE_LANGUAGES.map((lang) => (
            <Option
              onClick={() => handleLangClick(lang.abbreviation)}
              key={lang.abbreviation}
              fadedClr={lang.abbreviation === selectedOption}
            >
              {capitalizeFirstLetter(lang.language)}
            </Option>
          ))}
        </OptionsList>
      )}
    </Container>
  );
};

export default LanguageDropdown;
