import React, { useState } from 'react';
import styled from 'styled-components';
import HeaderItem from './components/HeaderItem';

import NavigationColor from 'utils/constants/NavigationColor';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import LanguageDropdown from 'components/LanguageDropdown';
import { rem } from 'polished';
import CloseHamburger from 'images/CloseHamburger.svg';
import { breakpoints } from 'theme';

const CONTACT_ITEMS = [
  {
    title: 'footer.contact.body',
    value: 'footer.contact.email',
  },
];

const NavigationList = styled.ul<{ toggleMobileNav: boolean }>`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 43px;

  @media (max-width: ${breakpoints.laptop}) {
    position: fixed;
    z-index: 1;
    inset: 0 0 0 16%;
    background-color: #fff;
    backdrop-filter: blur(1rem);

    flex-direction: column;
    align-items: right;

    transition: transform 200ms ease-in-out;
    transform: translateX(
      ${(props) => (props.toggleMobileNav ? '0%' : '100%')}
    );

    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 50px;
    padding-top: 200px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const DottedListItem = styled.li`
  position: relative;

  &:nth-child(-n + 3)::after {
    content: '• ';
    position: absolute;
    right: -17px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: ${breakpoints.laptop}) {
    &:nth-child(-n + 3)::after {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    &:nth-child(-n + 3)::after {
      display: none;
    }
  }
`;

const MobileNavToggle = styled.button<{
  toggleMobileNav: boolean;
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
}>`
  position: ${(props) => (props.toggleMobileNav ? 'fixed' : 'absolute')};
  z-index: 2;
  right: 24px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    width: 32px;
    height: 3px;
    display: ${(props) => (props.toggleMobileNav ? 'inline-block' : 'none')};
    background-color: ${(props) => props.theme.brandPalette.primary};
  }

  @media (min-width: ${breakpoints.tablet}) {
    color: ${(props) => props.theme.brandPalette.dark};
    font-size: ${rem(22)};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    line-height: 32px;

    span {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.laptop}) {
    cursor: pointer;

    span {
      display: ${(props) => (props.toggleMobileNav ? 'none' : 'inline-block')};
    }

    &::before {
      content: '';
      display: ${(props) => (props.toggleMobileNav ? 'inline-block' : 'none')};
      width: 40px;
      height: 40px;
      background-image: url(${CloseHamburger});
      background-size: cover;
      vertical-align: middle;
      cursor: pointer;
    }
  }
`;

const ContactListItem = styled.li`
  display: none;
  list-style-type: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
  }
  @media (max-width: ${breakpoints.laptop}) {
    display: block;
  }
`;

const ContactList = styled.ul`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    order: 1;
    margin-top: 70px;
  }
  @media (max-width: ${breakpoints.laptop}) {
    display: block;
    margin-top: 80px;
  }
`;

const ContactTitle = styled.h2`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin-top: 135px;
  margin-bottom: 20px;
`;

const ContactInfo = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  opacity: 0.7;
`;

interface Props {
  variant: NavigationColor.LIGHT | NavigationColor.DARK;
}

const HeaderMenu = ({ variant }: Props) => {
  const contacts = CONTACT_ITEMS;

  const [showMobileNav, setShowMobileNav] = useState(false);
  const { t } = useTranslation();

  const mobileNavButtonHandler = () => {
    setShowMobileNav((prevState) => !prevState);
  };

  return (
    <nav>
      <MobileNavToggle
        data-testid="hamburger-button"
        data-open={showMobileNav}
        toggleMobileNav={showMobileNav}
        onClick={mobileNavButtonHandler}
        variant={variant}
      >
        <span />
        <span />
        <span />
      </MobileNavToggle>
      <NavigationList toggleMobileNav={showMobileNav}>
        <DottedListItem>
          <HeaderItem active variant={variant} href="/" target="_blank">
            {t('nav_tab.home')}
          </HeaderItem>
        </DottedListItem>
        <DottedListItem>
          <HeaderItem variant={variant} href="/" target="_blank">
            {t('nav_tab.safebrok')}
          </HeaderItem>
        </DottedListItem>
        <DottedListItem>
          <HeaderItem variant={variant} href="/" target="_blank">
            {t('nav_tab.smart_products')}
          </HeaderItem>
        </DottedListItem>
        <DottedListItem>
          <HeaderItem variant={variant} href="/" target="_blank">
            {t('nav_tab.simulator')}
          </HeaderItem>
        </DottedListItem>
        <LanguageDropdown variant={variant} />
        <ContactList>
          {contacts.map((item) => (
            <ContactListItem key={item.title}>
              <ContactTitle> {t(item.title)} </ContactTitle>
              <ContactInfo> {t(item.value)} </ContactInfo>
            </ContactListItem>
          ))}
        </ContactList>
      </NavigationList>
    </nav>
  );
};

export default HeaderMenu;
