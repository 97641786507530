import React from 'react';
import styled from 'styled-components';

import HeaderMenu from './HeaderMenu';

import neowintechLogo from 'images/neowintech-logo.png';
import NavigationColor from 'utils/constants/NavigationColor';
import { Link } from 'gatsby-plugin-react-i18next';

import { breakpoints } from 'theme';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.laptop}) {
    padding: 14px 24px 14px 30px;
  }

  button {
    padding-bottom: 18px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  margin-left: 120px;
  margin-right: 80px;

  a {
    display: flex;
    align-items: center;
  }

  @media (max-width: ${breakpoints.laptop}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const NavigationWrapper = styled.div`
  display: flex;
  margin: 40px;
  margin-right: 200px;

  @media (max-width: ${breakpoints.laptop}) {
    margin: 0;
  }
`;

const CompanyLogo = styled.img`
  height: auto;
  width: 174px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-top: 15px;
  }
`;

interface Props {
  variant: NavigationColor.DARK;
}

const Header = ({ variant }: Props) => {
  return (
    <HeaderWrapper>
      <LogoWrapper>
        <Link className="desktopLogo" to="/">
          <CompanyLogo src={neowintechLogo} alt="Neowintech logo" />
        </Link>
      </LogoWrapper>
      <NavigationWrapper>
        <HeaderMenu variant={variant} />
      </NavigationWrapper>
    </HeaderWrapper>
  );
};

export default Header;
